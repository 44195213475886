import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import HomeView from "../views/HomeView.vue";

class TranslateList {
    en? = "";
    nl? = "";
    de? = "";
    fr? = "";
}

const pages: TranslateList = {
    en: "/page/:uri",
    nl: "/pagina/:uri",
    de: "/buchseite/:uri",
};

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        alias: ["/en", "/nl", "/fr", "/de"],
        name: "home",
        component: HomeView,
    },
    {
        path: "/shopping",
        name: "shopping",
        component: () =>
            import(/* webpackChunkName: "shopping" */ "../views/ShoppingView.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () =>
            import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
    },
    {
        path: "/cart",
        name: "cart",
        component: () =>
            import(/* webpackChunkName: "checkout" */ "../views/CartView.vue"),
    },
    {
        path: "/privacy",
        name: "privacy",
        component: () =>
            import(/* webpackChunkName: "privacy" */ "../views/PrivacyView.vue"),
    },
    {
        path: "/checkout",
        name: "checkout",
        beforeEnter() {
            window.location.href = process.env.VUE_APP_ADMIN_DOMAIN + "/" + process.env.VUE_APP_STORE_PREFIX + "/checkout" + "/onepage";
        },
        component: {
            template: "<div>Redirecting...</div>", // Dummy component voor correcte types
        },
    },
    {
        path: "/done/:id?",
        name: "done",
        alias: ["/done"],
        component: () =>
            import(/* webpackChunkName: "checkout" */ "../views/DoneView.vue"),
    },
    {
        path: "/products/:uri",
        name: "product-details",
        component: () =>
            import(/* webpackChunkName: "shopping" */ "../views/ProductView.vue"),
    },
    {
        path: "/faq",
        name: "faq",
        component: () =>
            import(/* webpackChunkName: "faq" */ "../views/FaqView.vue"),
    },
    {
        path: "/blog",
        name: "blog",
        component: () =>
            import(/* webpackChunkName: "blog" */ "../views/BlogView.vue"),
    },
    {
        path: "/blog/:uri",
        name: "single-blog",
        component: () =>
            import(/* webpackChunkName: "info" */ "../views/BlogSingleView.vue"),
    },
    {
        path: "/page/:uri",
        alias: ["/pagina/:uri", "/buchseite/:uri", "/:uri"],
        name: "single-page",
        component: () =>
            import(/* webpackChunkName: "info" */ "../views/PageView.vue"),
    },
    {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
            import(/* webpackChunkName: "signin" */ "../views/auth/SignInView.vue"),
    },
    {
        path: "/registration",
        name: "registration",
        component: () =>
            import(/* webpackChunkName: "registration" */ "../views/auth/RegistrationView.vue"),
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () =>
            import(/* webpackChunkName: "forgot-password" */ "../views/auth/ForgotPasswordView.vue"),
    },
    {
        path: "/reset-password/:hash",
        name: "reset-password",
        component: () =>
            import(/* webpackChunkName: "reset-password" */ "../views/auth/ResetPasswordView.vue"),
    },
    {
        path: "/profile",
        name: "profile",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/user/ProfileView.vue"),
    },
    {
        path: "/orders",
        name: "orders",
        component: () =>
            import(/* webpackChunkName: "orders" */ "../views/user/Orders.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

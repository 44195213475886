
import {Options, Vue} from "vue-class-component";
import NavBar from "@/components/NavBar.vue";
import {requests} from "@/helpers/requests";
import CartAlert from "./components/CartAlert.vue";
import Footer from "./components/Footer.vue";
import {CanOrderResponse} from "@/responses/webshop";
import {PageMenusResponse, TranslationsResponse} from "@/responses/pages";
import {CartResponse} from "@/responses/cart-resp";

@Options({
    components: {
        NavBar,
        CartAlert,
        Footer,
    },
    created() {

        if (process.env.NODE_ENV === "development") {
            this.$i18n.locale = "nl";
        } else {
            let languageSet = false;

            const pathSegments = location.pathname.split("/").filter(Boolean);
            const domain = location.origin;

            if (pathSegments.length > 0) {
                switch (pathSegments[0]) {
                    case "en":
                        this.$i18n.locale = "en";
                        languageSet = true;
                        break;
                    case "fr":
                        this.$i18n.locale = "fr";
                        languageSet = true;
                        break;
                    case "de":
                        this.$i18n.locale = "de";
                        languageSet = true;
                        break;
                    case "nl":
                        this.$i18n.locale = "nl";
                        languageSet = true;
                        break;
                    default:
                        break;
                }
            }

            if (!languageSet) {
                if (domain.includes(".fr")) {
                    this.$i18n.locale = "fr";
                } else if (domain.includes(".be")) {
                    this.$i18n.locale = "nl";
                } else if (domain.includes(".nl")) {
                    this.$i18n.locale = "nl";
                } else if (domain.includes(".de")) {
                    this.$i18n.locale = "de";
                } else if (domain.includes(".com")) {
                    this.$i18n.locale = "en";
                } else {
                    this.$i18n.locale = "en";
                }
            }
        }

        this.$store.commit('setLocale', this.$i18n.locale);

        // Voer de resterende functies uit
        requests.get("webshop/can_order").then((response: CanOrderResponse) => {
            this.$store.commit("setBlockedDates", response.blockedDates);
            this.blockedDates = response.blockedDates;
        });
        this.getTranslations();
    },

    watch: {
        "$i18n.locale": function () {
            // this.getMenus();
            this.getTranslations();
        },
    },
    methods: {
        getMenus() {
            requests
                .get("pages/menus/" + this.$i18n.locale)
                .then((response: PageMenusResponse) => {
                    this.$store.commit("setMenus", response.menus);
                });
        },
        getTranslations() {
            requests
                .get("translations/get?locale=" + this.$i18n.locale)
                .then((response: TranslationsResponse) => {
                    this.$store.commit("setTranslations", response.translations);
                    requests.get("checkout/cart").then((response: CartResponse) => {
                        this.$store.commit("setCart", response.data);
                    });
                });
        },
    },
})
export default class App extends Vue {
}
